/*! modernizr 3.7.1 (Custom Build) | MIT *
 * https://modernizr.com/download/?-input-setclasses !*/
!(function (e, n, t) {
  function s(e, n) {
    return typeof e === n;
  }
  function o() {
    return "function" != typeof n.createElement
      ? n.createElement(arguments[0])
      : f
      ? n.createElementNS.call(n, "http://www.w3.org/2000/svg", arguments[0])
      : n.createElement.apply(n, arguments);
  }
  var a = [],
    i = {
      _version: "3.7.1",
      _config: {
        classPrefix: "",
        enableClasses: !0,
        enableJSClass: !0,
        usePrefixes: !0
      },
      _q: [],
      on: function (e, n) {
        var t = this;
        setTimeout(function () {
          n(t[e]);
        }, 0);
      },
      addTest: function (e, n, t) {
        a.push({ name: e, fn: n, options: t });
      },
      addAsyncTest: function (e) {
        a.push({ name: null, fn: e });
      }
    },
    Modernizr = function () {};
  (Modernizr.prototype = i), (Modernizr = new Modernizr());
  var l = [],
    r = n.documentElement,
    f = "svg" === r.nodeName.toLowerCase(),
    c = o("input"),
    u = "autocomplete autofocus list placeholder max min multiple pattern required step".split(
      " "
    ),
    p = {};
  (Modernizr.input = (function (n) {
    for (var t = 0, s = n.length; t < s; t++) p[n[t]] = !!(n[t] in c);
    return p.list && (p.list = !(!o("datalist") || !e.HTMLDataListElement)), p;
  })(u)),
    (function () {
      var e, n, t, o, i, r, f;
      for (var c in a)
        if (a.hasOwnProperty(c)) {
          if (
            ((e = []),
            (n = a[c]),
            n.name &&
              (e.push(n.name.toLowerCase()),
              n.options && n.options.aliases && n.options.aliases.length))
          )
            for (t = 0; t < n.options.aliases.length; t++)
              e.push(n.options.aliases[t].toLowerCase());
          for (
            o = s(n.fn, "function") ? n.fn() : n.fn, i = 0;
            i < e.length;
            i++
          )
            (r = e[i]),
              (f = r.split(".")),
              1 === f.length
                ? (Modernizr[f[0]] = o)
                : (!Modernizr[f[0]] ||
                    Modernizr[f[0]] instanceof Boolean ||
                    (Modernizr[f[0]] = new Boolean(Modernizr[f[0]])),
                  (Modernizr[f[0]][f[1]] = o)),
              l.push((o ? "" : "no-") + f.join("-"));
        }
    })(),
    (function (e) {
      var n = r.className,
        t = Modernizr._config.classPrefix || "";
      if ((f && (n = n.baseVal), Modernizr._config.enableJSClass)) {
        var s = new RegExp("(^|\\s)" + t + "no-js(\\s|$)");
        n = n.replace(s, "$1" + t + "js$2");
      }
      Modernizr._config.enableClasses &&
        (e.length > 0 && (n += " " + t + e.join(" " + t)),
        f ? (r.className.baseVal = n) : (r.className = n));
    })(l),
    delete i.addTest,
    delete i.addAsyncTest;
  for (var m = 0; m < Modernizr._q.length; m++) Modernizr._q[m]();
  e.Modernizr = Modernizr;
})(window, document);
